import HeroSection from "./interface/Home/HeroSection";
import WebSection from "./interface/Home/WebSection";
import PrintSection from "./interface/Home/PrintSection";
import HardwareSection from "./interface/Home/HardwareSection";
import GallerySection from "./interface/Home/GallerySection";
import ClientsSection from "./interface/Home/ClientsSection";
import AboutSection from "./interface/Home/AboutSection";

{/*
WICHTIG:
Jede Sektion muss für eine flüssige Kamera-Animation mindestens 120vh hoch sein.
Bei großen Differenzwerten bei Kamera-Postion und -Rotation kann es zu Problemen kommen.
*/}

export default function Interface() {
    return (
        <>
        {/* <div className="h-screen w-[1px]"></div> */}
        {/* <div className="relative z-[1000]">
            <div className="flex flex-col gap-0"> */}
                <HeroSection />
                <div id="camera-fly-web" className="h-[50vh] my-4 w-[1px]"></div>
                <WebSection />
                <div id="camera-fly-print" className="h-[100vh] my-4 w-[1px]"></div>
                <PrintSection />
                <div id="camera-fly-gallery" className="h-[100vh] my-4 w-[1px]"></div>
                <GallerySection />
                <div id="camera-fly-hardware" className="h-[100vh] my-4 w-[1px]"></div>
                <div className="h-[100vh] my-4 w-[1px] bg-red-400"></div>
                <HardwareSection />                
                <div id="camera-fly-about" className="h-[100vh] my-4 w-[1px]"></div>
                <AboutSection />
            {/* </div>
        </div> */}
        </>
    );
}