import { useRef } from "react"
import { useFrame } from "@react-three/fiber"


export default function Planets(props) {

    const planetGroup = useRef()
    const mercury = useRef()
    const venus = useRef()
    const earth = useRef()
    const mars = useRef()
    const jupiter = useRef()
    const saturn = useRef()
    const uranus = useRef()
    const neptune = useRef()


    useFrame((state, delta) => {
        planetGroup.current.rotation.x += delta * 0.1
        planetGroup.current.rotation.y += delta * 0.2
        planetGroup.current.rotation.z += delta * 0.15

    })

    // MINDESTABSTAND WEGEN GROUND NOCH MIT EINBERECHNEN!

    return (
        <group ref={planetGroup} {...props}>
            <mesh ref={mercury} position={[-2, 1, -150]}>
                <sphereGeometry args={[1, 16]} />
                <meshStandardMaterial color={"#9a6738"} />
            </mesh>
            <mesh ref={venus} position={[-50, 30, -80]}>
                <sphereGeometry args={[1, 16]} />
                <meshStandardMaterial color={"#b92e17"} />
            </mesh>
            <mesh ref={earth} position={[100, 16, 60]}>
                <sphereGeometry args={[1, 16]} />
                <meshStandardMaterial color={"#1fa2d0"} />
            </mesh>
            <mesh ref={mars} position={[150, 71, 120]}>
                <sphereGeometry args={[1, 16]} />
                <meshStandardMaterial color={"#b15105"} />
            </mesh>
            <mesh ref={jupiter} position={[100, 36, 90]}>
                <sphereGeometry args={[1, 16]} />
                <meshStandardMaterial color={"#e3b466"} />
            </mesh>
            <mesh ref={saturn} position={[170, 120, 190]}>
                <sphereGeometry args={[1, 16]} />
                <meshStandardMaterial color={"#f5c176"} />
            </mesh>
            <mesh ref={uranus} position={[80, 131, 75]}>
                <sphereGeometry args={[1, 16]} />
                <meshStandardMaterial color={"#3c9c97"} />
            </mesh>
            <mesh ref={neptune} position={[140, 191, 161]}>
                <sphereGeometry args={[1, 16]} />
                <meshStandardMaterial color={"#4ea8dd"} />
            </mesh>
        </group>
    )
}