import React, { useEffect, useRef, useState, Suspense } from "react";
import * as THREE from 'three'
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls } from '@react-three/drei'
import { ReactLenis, useLenis } from 'lenis/react'
import { Leva } from 'leva'
import { Perf } from 'r3f-perf'

import useStore from "./Store";
import Preloader from './components/Preloader'
import Header from './components/interface/Header'
import Footer from './components/interface/Footer'
import Interface from './components/Interface'
import Experience from './components/Experience'
import Navigation from "./components/interface/Navigation";


const assetsToLoad = [
    '/images/coolcat.png',
    '/images/earth.png',
    '/images/island.png',
    '/images/me.png',
    '/images/mountains.png',
    '/images/raven.png',
    '/images/space.png',
    '/images/sundown.png',
    '/images/tree.png',
];

function App() {

    const loading = useStore((state) => state.loading);
    const setLoading = useStore((state) => state.setLoading);

    return (
        <>
        {loading && <Preloader assetsToLoad={assetsToLoad} onLoaded={() => { 
            setLoading(false)        
        }
        } />}
        {!loading && (
            <>
            <div className="fixed top-0 left-0 w-full h-screen">
            <Leva hidden />
            <Canvas 
                shadows 
                gl={ { 
                    // logarithmicDepthBuffer: true, 
                    antialias: true,
                    powerPreference: "high-performance",

                }}>
            <Suspense fallback={"Loading"}>
                <Perf position="bottom-right" style={{ right: 20, bottom: 80 }} />
                <Experience />
            </Suspense>
            </Canvas> 
            </div>  
            <Interface />
            <Header />
            <Footer />
            
            
            {/* <ReactLenis root options={{ 
                autoRaf: true,
                wheelMultiplier: 0.6,
                fastScrollEnd: 3000,
                easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t))
            }}> 
            </ReactLenis>    */}
            
            </>
        )}
        </>
    )
}

export default App