import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

function useParallax(value, distance) {
    return useTransform(value, [0, 1], [-distance, distance]);
}

export const MotionParallax = ({ children, distance = 300 }) => {
    
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({ target: ref });
    const y = useParallax(scrollYProgress, distance);

    return (
        <motion.div ref={ref} style={{ y }}>
            {children}
        </motion.div>
    );
};