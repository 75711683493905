import React, { useRef, useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Character(props) {

  const camera = useThree(state => state.camera)
  const groupRef = useRef()
  const { nodes, materials, animations } = useGLTF('/models/walker.glb', true)
  const { actions } = useAnimations(animations, groupRef)

  const actionWalking = actions["Armature|mixamo.com|Layer0.001"]

  useEffect(() => {
    if (actionWalking) actionWalking.play();
  }, [camera]);

  return (
    <group ref={groupRef} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.007}>
          <group name="Ch44">
            <skinnedMesh
              name="Mesh"
              geometry={nodes.Mesh.geometry}
              material={nodes.Mesh.material}
              skeleton={nodes.Mesh.skeleton}
            />
            <skinnedMesh
              name="Mesh_1"
              geometry={nodes.Mesh_1.geometry}
              material={nodes.Mesh_1.material}
              skeleton={nodes.Mesh_1.skeleton}
            />
          </group>
          <primitive object={nodes.mixamorigHips} />
        </group>
      </group>
    </group>
  )

}

useGLTF.preload('/models/walker.glb')
