import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import Section from "./SectionGenerator"

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin)

// ################################################
// Scrolldown Fader
// ################################################

// function HeroScrolldown() {

//     const scrolldownRef = useRef();
//     const [hasBeenAnimated, setHasBeenAnimated] = useState(false);

//     useGSAP((context, contextSafe) => {

//         // AnimateIn
//         // Wrap the function in contextSafe so that its properly cleaned up
//         // by GSAP for objects created afterwards
//         const animateIn = contextSafe(() => {
//             const tl = gsap.timeline();
//             tl.fromTo(scrolldownRef.current,
//                 { translateY: "100%", opacity: 0 },
//                 { translateY: "0", opacity: 1, duration: 0.5, delay: 0.5, ease: "power2.inOut" }
//             )
//             .then(() => {
//                 setHasBeenAnimated(true);
//             });
//         }); // animateIn


//         const animateOut = () => {
//             const tl = gsap.timeline();
//             tl.fromTo(scrolldownRef.current,
//                 { translateY: "0", opacity: 1 },
//                 { translateY: "100%", opacity: 0, duration: 0.5, delay: 0, ease: "power2.inOut" }
//             )
//             .then(() => {
//                 setHasBeenAnimated(false);
//             });
//         }

//         ScrollTrigger.create({
//             start: '+=200px',
//             onEnter: animateOut,
//             onLeaveBack: animateIn
//         });

//         if (hasBeenAnimated === false) {
//             animateIn()
//         }

//         return () => {
//             ScrollTrigger.killAll();
//         };

//     }, []) // useGSAP

//     return (
//         <div ref={scrolldownRef} className="inline-block relative mx-auto p-5 text-center">
//             <div className="scrolldown mx-auto mb-5"></div>
//             <div className="font-oxanium uppercase">Scroll to explore</div>
//         </div>        
//     )
// }


// ################################################
// Hero TextSlider
// ################################################

// const heroTextSliderArray = [
//     {
//         id: 1,
//         name: 'Web Experiences', 
//         desc: 'Atemberaubende Webseiten mit Stil und Finesse',
//     },
//     {
//         id: 2,
//         name: 'Printmedien und Werbetechnik', 
//         desc: '', 
//     },
//     {
//         id: 3,
//         name: 'IT & Hardware', 
//         desc: '', 
//     },
// ];


// function HeroTextSlider() {

//     // Wrapper
//     const textSliderWrapperRef = useRef();
//     const [wrapperHasBeenAnimated, setWrapperHasBeenAnimated] = useState(false);
    
//     // Items Array
//     const [currentTextSliderItemIndex, setcurrentTextSliderItemIndex] = useState(0);
//     const [isTextSliderItemAnimating, setisTextSliderItemAnimating] = useState(true);
//     const textSliderItemRefs = useRef([]);

//     useGSAP((context, contextSafe) => {

//         // Wrapper Animation and ScrollTrigger

//         const animateIn = contextSafe(() => {
//             const tl = gsap.timeline();
//             tl.fromTo(textSliderWrapperRef.current,
//                 { translateY: "0", opacity: 0 },
//                 { translateY: "0", opacity: 1, duration: 0.5, delay: 0.5, ease: "power2.inOut" }
//             )
//             .then(() => {
//                 setWrapperHasBeenAnimated(true);
//                 //itemAnimationLoop();
//             });
//         });

//         const animateOut = contextSafe(() => {
//             const tl = gsap.timeline();
//             tl.fromTo(textSliderWrapperRef.current,
//                 { translateY: "0", opacity: 1 },
//                 { translateY: "0", opacity: 0, duration: 0.5, delay: 0, ease: "power2.inOut" }
//             )
//             .then(() => {
//                 setWrapperHasBeenAnimated(false);
//             });
//         })

//         const textSliderScrollTrigger = ScrollTrigger.create({
//             start: '+=200px',
//             onEnter: animateOut,
//             onLeaveBack: animateIn
//         });


//         // AnimateIn the Wrapper on first render
//         if (wrapperHasBeenAnimated === false) {
//             //textSliderScrollTrigger()
//             animateIn()
//         }

//         return () => {
//             //ScrollTrigger.killAll();
//             //textSliderScrollTrigger.kill();
//         };

//     }, {dependencies: [currentTextSliderItemIndex, isTextSliderItemAnimating], scope: textSliderWrapperRef}); // useGSAP


//     return (
//         <div ref={textSliderWrapperRef} className="w-full max-w-7xl mx-auto px-5 text-center">
//             { heroTextSliderArray.map((item, index) => (
//             <div 
//                 key={item.id} 
//                 ref={(el) => (textSliderItemRefs.current[index] = el)} 
//                 className={`transition-opacity duration-500 ${index === currentTextSliderItemIndex ? 'opacity-100' : 'opacity-0'}`}>
//                 <h1 className="inline-block text-eyecatcher-lg font-oxanium uppercase border border-white/60 py-8 px-16">{item.name}</h1>
//                 <div className="text-orange-600">{item.desc}</div>
//                 Creative. Innovation. Passion.
//             </div>
//             ))}
//         </div>    
//     )
// }

// ################################################
// Export HeroSection
// ################################################

export default function HeroSection() {

    return (
        <Section name="section-hero">{/* 
        <div className="flex-1 h-full grid grid-rows-4">
        <div className="row-span-2 lg:row-span-3 flex flex-col justify-center">
            <HeroTextSlider />
            <div className="w-full max-w-7xl mx-auto px-5">
                <div className="text-lg">Willkommen bei SystemRuhr</div>
                <h1 className="text-eyecatcher-lg font-oxanium uppercase">Fluid Motion Design</h1>
                <div className="text-orange-600">Mein Name ist Dennis Hochhaus und ich erstelle atemberaubende Webseiten.</div>
            </div> 
        </div>
        <div className="row-span-1 lg:hidden"></div>
        <div className="row-span-1 flex flex-col justify-center">
            <HeroScrolldown />
        </div>
        </div>

        <div className="min-h-screen relative">
            <HeroScrolldown />
        </div>*/}
        </Section>
    )
}