import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";

import { config } from "../../../config";
import Section from "./SectionGenerator"

import { IconContext } from "react-icons";
import { ImXing } from "react-icons/im";
import { GrLinkedin } from "react-icons/gr";

// ################################################
// Funfacts
// ################################################

function GenerateFunFacts() {

    const [currentFunfactIndex, setcurrentFunfactIndex] = useState(0);
    const [isFunfactAnimating, setisFunfactAnimating] = useState(true);
    const funfactsRefs = useRef([]);
    const funfactsContainer = useRef([]);
    const progressBar = useRef();

    useGSAP((context, contextSafe) => {

        // AnimateIn

        const animateIn = contextSafe(() => {
          
          const tl = gsap.timeline();

          tl.to(progressBar.current, { width: 0, duration: 1 })

          tl.fromTo(
            funfactsRefs.current[currentFunfactIndex].querySelector('.icon'),
            { translateX: "-100%" },
            { translateX: "0", duration: 0.5, ease: "power2.inOut" }
          )
            .fromTo(
              funfactsRefs.current[currentFunfactIndex].querySelector('.text'),
              { opacity: 0, x: 0 },
              { opacity: 1, x: 0, duration: 0.9 },
              '<'
            )
            .then(() => {
                setisFunfactAnimating(false);
            });
        });
    
        // AnimateOut

        const animateOut = contextSafe(() => {

          const tl = gsap.timeline();
    
          tl.to(funfactsRefs.current[currentFunfactIndex].querySelector('.icon'), {
                translateX: "-100%",
                duration: 0.5,
                ease: "power2.inOut"
            })
            .to(funfactsRefs.current[currentFunfactIndex].querySelector('.text'), { 
                opacity: 0, 
                x: 0, 
                duration: 0.7 
            }, '<')
            .then(() => {
                setcurrentFunfactIndex((prevIndex) =>
                    prevIndex === config.funfacts.length - 1 ? 0 : prevIndex + 1
                );
                setisFunfactAnimating(true);
            });
        });
    
        // AnimateOut the ProgressBar

        const animateOutProgressBar = contextSafe(() => {
            const tl = gsap.timeline();
            tl.to(progressBar.current, { width: "100%", duration: 5 })
        })


        if (isFunfactAnimating) {

          animateIn();

        } else {

            animateOutProgressBar();
            const timer = setTimeout(() => {
                animateOut();
            }, 5000);
            return () => clearTimeout(timer);
        }

      }, {dependencies: [currentFunfactIndex, isFunfactAnimating], scope: funfactsContainer});


    return (
        <>
        <div ref={funfactsContainer} className="h-20 relative overflow-hidden">
        { config.funfacts.map((item, index) => (
            <div 
                key={item.id} 
                ref={(el) => (funfactsRefs.current[index] = el)} 
                className={`absolute w-full h-full transition-opacity duration-500 ${index === currentFunfactIndex ? 'opacity-100' : 'opacity-0'}`}>
                <div className="flex items-center">
                    <div className="icon text-[50px] mr-3">{item.icon}</div>
                    <div className="text text-xl font-oxanium">{item.text}</div>
                </div>
            </div>
        ))}
        </div>
        <div className="bg-emerald-900 ">
            <div ref={progressBar} className="h-4 bg-emerald-400" style={{ width: "0%" }}></div>
        </div>
        </>
    )
}

// ################################################
// Skills
// ################################################

function GenerateSkills() {
    const [currentSkillIndex, setcurrentSkillIndex] = useState(0);
    const [isSkillAnimating, setisSkillAnimating] = useState(true);
    const skillsRefs = useRef([]);
    const skillsContainer = useRef();

    useGSAP((context, contextSafe) => {

        // AnimateIn
        // Wrap the function in contextSafe so that its properly cleaned up
        // by GSAP for objects created afterwards
        const animateIn = contextSafe(() => {
          
          const tl = gsap.timeline();

          tl.fromTo(
            skillsRefs.current[currentSkillIndex].querySelector('.icon'),
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: 1 }
          )
            .fromTo(
              skillsRefs.current[currentSkillIndex].querySelector('.text'),
              { opacity: 0, x: -20 },
              { opacity: 1, x: 0, duration: 1 },
              '<'
            )
            .then(() => {
                setisSkillAnimating(false);
            });
        });
    

        // AnimateOut
        // Wrap the function in contextSafe so that its properly cleaned up
        // by GSAP for objects created afterwards
        const animateOut = contextSafe(() => {

          const tl = gsap.timeline();
    
          tl.to(skillsRefs.current[currentSkillIndex].querySelector('.icon'), {
                opacity: 0, 
                y: -20, 
                duration: 1
            })
            .to(skillsRefs.current[currentSkillIndex].querySelector('.text'), { 
                opacity: 0, 
                x: 20, 
                duration: 1 
            }, '<')
            .then(() => {

                setcurrentSkillIndex((prevIndex) =>
                    prevIndex === config.skills.length - 1 ? 0 : prevIndex + 1
                );
                setisSkillAnimating(true); // Animation neu starten
            });
        });
    

        if (isSkillAnimating) {

          animateIn();

        } else {

            const timer = setTimeout(() => {
                animateOut();
            }, 5000);

            return () => clearTimeout(timer);
        }

    }, {dependencies: [currentSkillIndex, isSkillAnimating], scope: skillsContainer});


    
    return (
        <IconContext.Provider value={{ color: "white", className: "text-[100px] lg:text-[140px] mx-auto" }}>
        <div ref={skillsContainer} className="flex-1 h-80 text-center overflow-hidden relative ">
            {config.skills.map((item, index) => (
                <div 
                ref={(el) => (skillsRefs.current[index] = el)} 
                key={item.id} 
                className={`absolute w-full h-full transition-opacity duration-500 ${index === currentSkillIndex ? 'opacity-100' : 'opacity-0'}`}>                
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <div className="icon">{item.icon}</div>
                        <div className="text uppercase font-oxanium pt-4 text-3xl text-emerald-400">{item.name}</div>
                    </div>
                </div>
            ))}
        </div>
        </IconContext.Provider>
    )
}

// ################################################
// Export AboutSection
// ################################################

export default function AboutSection() {

    return (
        <Section name="section-about">
        {/*<div className="min-h-screen"></div>
        <div className="min-h-screen bg-gradient-to-b from-transparent to-black/80"></div> */}
        <div className=" pt-12 pb-20 min-h-screen">
        <div className="w-full max-w-7xl mx-auto px-5">

            <div className="flex gap-10 mb-14 items-center">
                <h1 className="flex-none text-eyecatcher-xl font-oxanium uppercase">Über mich</h1>
                <div className="grow bg-emerald-400 h-1"></div>
            </div>

            <div className="grid grid-cols-3 gap-8">
            <div className="col-span-3 lg:col-span-2 p-6 lg:p-10 bg-neutral-950/80 shadow-lg relative">
                
             
                <div className="pl-8 py-4 border-l-8 border-emerald-400 uppercase">
                    <div className="font-oxanium text-eyecatcher-md">Dennis Hochhaus</div>
                    Gründer SystemRuhr
                </div>
                
                <div className="md:pl-10 py-6 mb-6 text-gray-400 ">
                    <p className="my-2">Hallo! Mein Name ist Dennis Hochhaus und ich bin der kreative Kopf hinter SystemRuhr. Bereits 2009 habe ich dieses Projekt als Kleinunternehmen im Herzen des Ruhrgebiets gegründet.</p>
                    <p className="my-2">Seither habe ich immer wieder kleine und mittelständische Unternehmen beim Aufbau Ihrer Marke / Corporate Identity als auch im Bereich IT-Infrastruktur, Hardware und Software betreut.</p>
                    <p className="my-2">Dabei habe ich es mir zur Aufgabe gemacht, auf ehrliche, ruhrpott-typische Art und Weise mein Wissen und meine Fertigkeiten, die ich in vielen Jahren als Frontend-Developer und Führungskraft eines mittelständischen Unternehmens gesammelt habe, in Ihr Projekt einzubringen.</p>
                </div>


                <div className="mb-10 grid grid-cols-2 gap-0 lg:gap-10">
                <div className="col-span-2 md:col-span-1">
                    <div className="flex gap-10 items-center">
                        <div className="flex-none">Technik-Liebhaber</div>
                        <div className="grow bg-emerald-400 h-[1px]"></div>
                    </div>
                    <p className="my-3 text-gray-400 text-sm">Vom Kassetten-Rekorder bis hin zum KI-Tool. Bereits seit Kindertagen technik-interessiert und -affin</p>

                    <div className="flex gap-10 items-center">
                        <div className="flex-none">Has broad tech knowledge</div>
                        <div className="grow bg-emerald-400 h-[1px]"></div>
                    </div>
                    <p className="my-3 text-gray-400 text-sm">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus debitis quas quibusdam.</p>

                </div>
                <div className="col-span-2 md:col-span-1">

                    <div className="flex gap-10 items-center">
                        <div className="flex-none">Dives deep into newest technologies</div>
                        <div className="grow bg-emerald-400 h-[1px]"></div>
                    </div>
                    <p className="my-3 text-gray-400 text-sm">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus debitis quas quibusdam.</p>

                    <div className="flex gap-10 items-center">
                        <div className="flex-none">Seit Flash 3 dabei</div>
                        <div className="grow bg-emerald-400 h-[1px]"></div>
                    </div>
                    <p className="my-3 text-gray-400 text-sm">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Accusamus debitis quas quibusdam.</p>

                </div>
                </div>



                <div className="relative padding-10 bg-emerald-600/10">
                <div className="w-4 h-4 border-t border-l border-emerald-400 absolute left-0 top-0"></div>
                <div className="w-4 h-4 border-t border-r border-emerald-400 absolute right-0 top-0"></div>
                <div className="w-4 h-4 border-b border-l border-emerald-400 absolute left-0 bottom-0"></div>
                <div className="w-4 h-4 border-b border-r border-emerald-400 absolute right-0 bottom-0"></div>
                <div className="p-6 lg:p-10">
                    <GenerateFunFacts />
                    <div className="mt-5">
                        <div className="bg-emerald-400 h-2 w-2 mr-2 float-start"></div>
                        <div className="bg-emerald-400 h-2 w-2 mr-2 float-start"></div>
                        <div className="bg-emerald-400 h-2 w-2 mr-2 float-start"></div>
                    </div>
                </div>
                </div> 



            </div>
            <div className="col-span-3 lg:col-span-1 md:grid md:grid-cols-2 gap-x-5 md:gap-x-0 md:gap-y-4">

                <div className="md:grow col-span-2 md:col-span-1 lg:col-span-2 bg-neutral-950/80">
                    <img src="/images/me.png" alt="Coole Katze" className="w-full grayscale" />
                </div>
                <div className="md:grow col-span-2 md:col-span-1 lg:col-span-2 md:flex md-flex-col md:items-center bg-neutral-950/80 relative overflow-hidden mt-8 md:mt-0 lg:mt-4">
                    <div className="absolute left-5 top-1/2 -translate-x-1/2 -rotate-90 origin-top uppercase font-oxanium lg:text-2xl opacity-20">I work with</div>
                    <div className="w-4 h-4 border-t border-l border-emerald-400 absolute left-4 top-4"></div>
                    <div className="w-4 h-4 border-t border-r border-emerald-400 absolute right-4 top-4"></div>
                    <div className="w-4 h-4 border-b border-l border-emerald-400 absolute left-4 bottom-4"></div>
                    <div className="w-4 h-4 border-b border-r border-emerald-400 absolute right-4 bottom-4"></div>                    
                    <GenerateSkills />
                </div>

            </div>
            </div>


            <div className="grid grid-cols-6 gap-8 mt-8">
            <div className="col-span-6 lg:col-span-2 p-5 bg-neutral-950/80 grid grid-cols-2 text-center items-center">
                <div className="col-span-1 border-r border-emerald-800 pr-3">
                <div className="font-oxanium font-bold text-eyecatcher-md mb-2 bg-clip-text text-transparent bg-gradient-to-b from-white to-gray-400">71</div>
                    <div className="text-xs uppercase">Projekte realisiert</div>
                </div>
                <div className="col-span-1 text-lg pl-3">
                    <div className="font-oxanium font-bold text-eyecatcher-md mb-2 bg-clip-text text-transparent bg-gradient-to-b from-white to-gray-400">48</div>
                    <div className="text-xs uppercase">glückliche Kunden</div>                    
                </div>
            </div>
            <div className="col-span-6 md:col-span-3 lg:col-span-2 p-5 bg-neutral-950/80">
                <div className="border-b border-emerald-400 pb-2 mb-4">An einer Zusammenarbeit interessiert?</div>
                <div className="text-gray-400">Nimm über die sozialen Netze Kontakt auf oder sende eine Mail an <span className="text-white">hallo@systemruhr.de</span></div>
            </div>
            <div className="col-span-6 md:col-span-3 lg:col-span-2 p-5 bg-neutral-950/80">
                <div className="border-b border-emerald-400 pb-2 mb-4">Kontakt aufnehmen</div>
                <IconContext.Provider value={{ className: "text-[24px] text-neutral-700 hover:text-emerald-400" }}>
                    <a href="" className="text-gray-400 hover:text-white mr-6 link-hover-emerald-400"><ImXing className="inline-block hover-icon mr-1" /> Xing</a>
                    <a href="" className="text-gray-400 hover:text-white mr-6 link-hover-emerald-400"><GrLinkedin className="inline-block hover-icon mr-1" /> LinkedIn</a>
                </IconContext.Provider>
            </div>
            </div>


        </div>
        </div>
        </Section>
    )
}