import Section from "./SectionGenerator"
import { config } from "../../../config";
import { MotionReveal } from "../../utils/MotionReveal";
import { MotionParallax } from "../../utils/MotionParallax";
import { MotionFadeInBottom } from "../../utils/MotionFadeInBottom";

export default function HardwareSection() {
    return (
        <Section name="section-hardware">
        <div className="flex flex-col grow justify-center">
        <div className="w-full max-w-7xl mx-auto px-5">

        <div className="grid gap-8 md:grid-cols-2">
            {config.itservices.map((service, index) => (
            <div key={index}>
            <div className="border-t border-emerald-400 flex align-middle backdrop-blur-md bg-black/60 p-5">
                <div className="w-[46px] h-[46px] p-[5px] mr-5 bg-emerald-400 text-white text-center">
                    { service.icon }
                </div>
                <div className="self-start py-4">
                    <h4 className="font-bold text-xl block mb-1 text-white font-oxanium uppercase">{ service.name }</h4>
                    { service.desc }
                </div>
            </div>
            </div>
            ))}
        </div>

        </div>
        </div>
        </Section>
    )
}