import { create } from "zustand";

const useStore = create((set) => ({
    
    // Loading
    loading: true,
    setLoading: (status) => set({ loading: status }),
  
    // Attach complete
    attachComplete: false,
    setAttachComplete: (status) => set({ attachComplete: status }),

    // Sound on/off
    soundOn: false,
    setSoundOn: (status) => set({ soundOn: status }),

    // Navigation open
    navOpen: false,
    setNavOpen: (status) => set({ navOpen: status }),

}));

export default useStore;