import { useLoader } from "@react-three/fiber";
import { TextureLoader } from 'three/src/loaders/TextureLoader'

export default function Print() {

    const texCard   = useLoader(TextureLoader, "/textures/tex-card.png");
    const texPaper  = useLoader(TextureLoader, "/textures/tex-paper.png");

    return (
        <>
        {/* Business Card */}
        <mesh rotation={[0, -Math.PI * 0.6, 0]} position={[0, 0.275, 0]}>
            <planeGeometry args={[0.85, 0.55]} />
            <meshStandardMaterial map={texCard} envMapIntensity={0} />
        </mesh>
        {/* Business Paper */}
        <mesh rotation={[Math.PI / 2, Math.PI, Math.PI / 2]} position={[1, 0.001, -1]}>
            <planeGeometry args={[2.1, 2.97]} />
            <meshStandardMaterial map={texPaper} />
        </mesh>
        </>
    )
}