uniform float uTime;
uniform vec3 uColor;

attribute float aSpeed;
varying vec2 vUv;

void main() {
    vec3 pos = position;
    if (pos.x < 0.0) {
    pos.y += sin(uTime * aSpeed) * 0.1;
    } else {
    pos.y += cos(uTime * aSpeed) * 0.2;
    }
    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
    vUv = uv;
}