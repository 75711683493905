import * as THREE from 'three'
import React, { useRef, useEffect, forwardRef, useState, Suspense, useMemo } from 'react'
import { useGLTF, useTexture, useVideoTexture, useAspect, Center } from '@react-three/drei'
import { applyProps, useFrame } from '@react-three/fiber'

export default function Monitor(props) {
  
    const { scene, nodes, materials } = useGLTF('/models/monitor.glb')
    const videoTexture = useVideoTexture("./video/text_tippen.mp4");
    const monitorRef = useRef()
    const monitorDisplayRef = useRef()

    const videoMatTest = useMemo(() => new THREE.MeshBasicMaterial({ map: videoTexture }), [videoTexture])

    useEffect(() => {
        //applyProps(monitorRef.current, {rotation: [0, Math.PI / 1.5, 0], position: [1, 1, 0]})
    }, [props])
    

    useEffect(() => {
        //applyProps(monitorRef.current, {rotation: [0, Math.PI / 1.5, 0], position: [1, 1, 0]})
        // console.log(nodes.monitor_2.geometry)
        // console.log(materials.display)
        // console.log(videoMatTest)
        //monitorDisplayRef.current.material = videoMatTest
        //monitorDisplayRef.current.position.x = 1
    }, [])

    useFrame(({ clock }) => {
        if (monitorRef.current) {
            //monitorRef.current.rotation.y = clock.getElapsedTime() * 0.2;
        }
    });




    return (
        <>
        <group {...props} dispose={null}>
            <group 
                ref={monitorRef} >
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.monitor_1.geometry}
                    material={materials.body}
                />
                <mesh 
                    ref={monitorDisplayRef}
                    castShadow
                    receiveShadow
                    geometry={nodes.monitor_2.geometry} 
                    rotation={[0, Math.PI, 0]}
                    position={[-5.6, 0, 0]}
                    //material={materials.display}
                >
                    <meshBasicMaterial map={videoTexture} doubleSided />
                </mesh>
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.monitor_3.geometry}
                    material={materials.holder}
                />
            </group>
        </group>
        {/* <mesh position={[-1.2, 0, 1]}>
            <boxGeometry />
            <meshBasicMaterial map={videoTexture} />
        </mesh> */}
        </>
    )
}

useGLTF.preload('/models/monitor.glb')