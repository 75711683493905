import './style.css'

import React from "react";
import ReactDOM from 'react-dom/client'
import App from "./App.jsx";

const root = document.getElementById('root');
if (!root._rootInstance) {
    root._rootInstance = ReactDOM.createRoot(root);
}

root._rootInstance.render(
    <App />
    // <React.StrictMode></React.StrictMode>
);