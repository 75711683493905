import { useEffect, useRef } from "react"
import * as THREE from 'three'
import { useThree, useLoader, extend } from '@react-three/fiber';
import { shaderMaterial, useTexture } from "@react-three/drei";
import { TextureLoader } from 'three/src/loaders/TextureLoader'

import vertexShader from "../../shader/galleryVertexShader.glsl";
import fragmentShader from "../../shader/galleryFragmentShader.glsl";

const ImageSliderMaterial = shaderMaterial({
    uTexture: undefined,
}, vertexShader, fragmentShader)
extend({ ImageSliderMaterial })

export default function Gallery({ width = 25, height = 18 }) {

    const galleryProjector = useRef();
    const image = "/images/earth.png";
    const texture = useTexture(image)

    return (
        <group>
            <mesh 
                ref={galleryProjector} 
                rotation={[Math.PI, 0, Math.PI]} 
                position={[0, (height / 2) + 0.3, 0]} 
                onClick={() => { 
                    
                }}>
                <planeGeometry args={[width, height, 32, 32]} />
                 <imageSliderMaterial 
                    side={THREE.DoubleSide}
                    uTexture={texture} 
                    depthTest={true}
                    depthWrite={true} /> 
            </mesh>
        </group>
    )
}