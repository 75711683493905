import React, { useEffect, useRef } from "react";
import * as THREE from 'three'
import { useFrame, useThree, useLoader } from '@react-three/fiber'
import { Environment, Lightformer, ContactShadows, Grid, PositionalAudio, AccumulativeShadows, OrbitControls, Line, Text, Text3D, Stars } from '@react-three/drei'
import { Bloom, DepthOfField, EffectComposer, Noise, Vignette, SMAA } from '@react-three/postprocessing'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { EXRLoader } from 'three-stdlib'
import { useControls } from "leva";

import useStore from "../Store";

import ResponsiveCamera from './canvas/ResponsiveCamera'
import Ground from './canvas/Ground'
import Planets from './canvas/Planets'

import Portal from "./canvas/Portal";
import Monitor from './canvas/Monitor'
import Print from "./canvas/Print";
import Gallery from "./canvas/Gallery";
import Fan from './canvas/Fan'
import Character from './canvas/Character'


function Env({ background, intensity = 1, blur = 0 }) {
 
  const envTexture    = useLoader(EXRLoader, "/maps/envMap.exr")
  // const envTexture = useLoader(TextureLoader, './maps/envMap.png') // doesnt look good
  const envMeshRef = useRef()
  
  return (
    <Environment background={background} blur={blur} resolution={512}>
      <color attach="background" args={['black']} />
      <mesh ref={envMeshRef}>
        <sphereGeometry />
        <meshBasicMaterial 
          transparent 
          opacity={intensity} 
          map={envTexture} 
          side={THREE.BackSide} 
          toneMapped={false} />
      </mesh>
    </Environment>
  )
}


export default function Experience()
{
  
  const state = useThree()
  const scene = useThree(state => state.scene)

  // Leva settings

  //Assign envMapIntensity to all objects
  const { envMapIntensity } = useControls("Environment", {
    envMapIntensity: { value: 1, min: 0, max: 10, step: 0.1 },
  });

  useEffect(() => {
    scene.environmentIntensity = envMapIntensity
  }, [ envMapIntensity ])


  // Environment settings
  const { background, blur, intensity, ...rotations } = useControls({
    background: true,
    x: { value: 0, min: -Math.PI, max: Math.PI },
    y: { value: 0, min: -Math.PI, max: Math.PI },
    z: { value: 0, min: -Math.PI, max: Math.PI },
    blur: { value: 0, min: 0, max: 1 }, // 0.25
    intensity: { value: 1, min: 0, max: 5 }
  })

  // Setup Audio
  const soundOn = useStore((state) => state.soundOn);
  const audioRef1 = useRef();
  const refGrid = useRef()

  useEffect(() => {
    if (soundOn) {
      audioRef1.current?.play();
    } else {
      audioRef1.current?.pause();
    }
  }, [soundOn]);


  // Stars
  const starsGroupRef = useRef();


  // Animations

  useFrame(( { clock } ) => { 

      const elapsedTime = clock.getElapsedTime();

      scene.environmentRotation.x = elapsedTime * 0.15
      scene.backgroundRotation.x = elapsedTime * 0.15
      scene.environmentRotation.y = elapsedTime * 0.1
      scene.backgroundRotation.y = elapsedTime * 0.1
      scene.environmentRotation.z = elapsedTime * 0.1
      scene.backgroundRotation.z = elapsedTime * 0.1

      starsGroupRef.current.rotation.x += 0.0003;
      starsGroupRef.current.rotation.y += 0.0001;
      starsGroupRef.current.rotation.z += 0.0001;
  }, []);


useEffect(() => {
  // console.log(state.scene)
  // console.log(state.gl)
  // console.log(state.gl)
})


  return <>
    <ResponsiveCamera />
    <Env 
      background={background} 
      blur={blur} 
      intensity={intensity} />
    <Planets />
    {/* <Ground 
      position={[0, -0.01, 0]} /> */}
    <Grid 
      ref={refGrid} 
      sectionSize={10}
      sectionColor={"blue"}
      sectionThickness={0.0}
      cellSize={2}
      cellColor={"#2dd4bf"}
      cellThickness={1}
      infiniteGrid
      fadeDistance={250}
      fadeStrength={15}
      position={[0, 0.001, 0]}
      followCamera={true}
      depthTest={true}
      depthWrite={true} 
      /> 
    

    <mesh position={[0, 0, 0]} visible={false}>
      <boxGeometry args={[1, 1, 1]}   />
      <meshStandardMaterial color="red" />
      <PositionalAudio ref={audioRef1} url="/sound/background-loop.mp3" loop autoplay />
    </mesh>

    
    <group ref={starsGroupRef}>
    <Stars 
      radius={100}
      depth={20}
      count={5000}
      factor={5}
      saturation={0}
      fade
      speed={0.4}
    />
    </group>

    {/* 
    ######################################################
    Hero
    ######################################################
    */}
    <group position={[0, 0, 0]}>
      <Text
        font="/fonts/Oxanium/Oxanium-Bold.ttf"
        fontSize={ 0.6 }
        color="white" 
        position={[0, 0.001, 1]}
        rotation={[-Math.PI / 2, 0, 0]}
        maxWidth={ 2 }
        textAlign="center">creative experiences</Text>
      <Portal />
      <group position={[0, 0, 0]}>
      <Line
        points={[
          [-2, 0, 0],
          [2, 0, 0],
          [2, 0, 2],
          [-2, 0, 2],
          [-2, 0, 0],
        ]}
        color="#2dd4bf"
        lineWidth={4}
      />
      </group>
    </group>


    {/* 
    ######################################################
    Web
    ######################################################
    */}
    <group position={[30, 0, 30]} rotation={[0, -Math.PI * 0.5, 0]}>
      {/* <Text
        font="/fonts/Oxanium/Oxanium-Bold.ttf"
        fontSize={ 0.6 }
        color="white"
        position={[0, 0.001, 1.5]}
        rotation={[-Math.PI / 2, 0, 0]}
        maxWidth={ 2 }
        textAlign="center">WEB</Text> */}
      <Monitor position={[0.45, 0.8, 1]} rotation={[-Math.PI, Math.PI / 1.3, 0]} scale={[-0.02, -0.42, -0.76]} />
    </group>


    {/* 
    ######################################################
    Print
    ######################################################
    */}  
    <group position={[30, 0.001, 60]}>
      <Print />
    </group>


    {/* 
    ######################################################
    Gallery
    ######################################################
    */}
    <group position={[0, 30, 110]}>
      <Gallery />
    </group>


    {/* 
    ######################################################
    Hardware
    ######################################################
    */}  
    <group position={[-30, 0.001, 60]}>
      <Fan scale={[0.5, 0.5, 0.5]} rotation={[0, 0, 0]} position-y={0.35} />
    </group>


    {/* 
    ######################################################
    About
    ######################################################
    */}
    <group position={[-30, 0.35, 30]}>
      <Character />
    </group>


    {/*
    <directionalLight position={ [ 1, 2, 3 ] } intensity={ 1.5 } />
    <ambientLight intensity={ 0.2 } />
    <pointLight position={[0, 10, -5]} intensity={10} color="#fff" />
    <AccumulativeShadows temporal frames={100} color="orange" colorBlend={2} toneMapped={true} alphaTest={0.75} opacity={2} scale={12}>
      <RandomizedLight intensity={Math.PI} amount={8} radius={4} ambient={0.5} position={[5, 5, -10]} bias={0.001} />
    </AccumulativeShadows>
    */}
    
    {/* 
    <EffectComposer multisampling={8}>
      <SMAA />
      <Vignette offset={0.5} darkness={0.7} />
    </EffectComposer>*/}
  </>

}