import Section from "./SectionGenerator"
import { config } from "../../../config";
import { MotionReveal } from "../../utils/MotionReveal";
import { MotionParallax } from "../../utils/MotionParallax";
import { MotionFadeInBottom } from "../../utils/MotionFadeInBottom";

export default function WebSection() {
    return (
        <Section name="section-web">
        <div className="flex flex-col grow justify-center py-10 ">
        <div className="w-full max-w-7xl mx-auto px-5">

            <div className="text-center lg:text-left ">
                <MotionReveal>
                    <h1 className="text-eyecatcher-xl font-oxanium">Web Developement</h1>
                </MotionReveal>
            </div>

            {/* <div className="mt-[30px] lg:mt-[40px] p-10 bg-neutral-950/90 ms-24">
                <p>Ich gestalte digitale Erlebnisse, die nicht nur visuell beeindrucken, sondern auch funktional überzeugen. Durch benutzerzentriertes Design und moderne Technologien kreiere ich intuitive, responsive Websites und Apps, die eine nahtlose und angenehme Interaktion ermöglichen. Jede digitale Lösung wird individuell angepasst, um die Bedürfnisse der Nutzer zu erfüllen und langfristige Bindungen zu schaffen. Mein Ziel ist es, Erlebnisse zu schaffen, die sowohl ästhetisch ansprechend als auch technisch ausgereift sind.</p>
            </div> */}

            <div className="grid lg:grid-cols-2 gap-y-4 gap-x-10 lg:gap-x-10 mt-[30px] lg:mt-[50px]">
            {config.webservices.map((service, index) => (
                <MotionFadeInBottom key={index} delay={0.2}>
                <div className="border-t border-emerald-400 flex align-middle backdrop-blur-md bg-black/60 p-5">
                    <div className="w-[46px] h-[46px] p-[5px] mr-5 bg-emerald-400 text-white text-center">
                        { service.icon }
                    </div>
                    <div className="self-start py-4">
                        <h4 className="font-bold text-xl block mb-1 text-white font-oxanium uppercase">{ service.name }</h4>
                        { service.desc }
                    </div>
                </div>
                </MotionFadeInBottom>
            ))}
            </div>


            <MotionParallax distance={300}>
                Parallax-Item
            </MotionParallax>

            {/* <a className="btn-cta-animated" href="#"><span>Super Button</span></a> */}

        </div>            
        </div>            
        </Section>
    )
}