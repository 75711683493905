import Section from "./SectionGenerator"

export default function GallerySection() {

    const images = [
        //{id: 3, img: '/images/island.png', alt: 'ImageAlt'},
        // {id: 1, img: '/images/coolcat.png', alt: 'ImageAlt'},
        // {id: 8, img: '/images/tree.png', alt: 'ImageAlt'},
        // {id: 2, img: '/images/earth.png', alt: 'ImageAlt'},
        // {id: 4, img: '/images/mountains.png', alt: 'ImageAlt'},
        // {id: 5, img: '/images/raven.png', alt: 'ImageAlt'},
        // {id: 6, img: '/images/space.png', alt: 'ImageAlt'},
        // {id: 7, img: '/images/sundown.png', alt: 'ImageAlt'},

    ];

    return (
        <Section name="section-gallery">
        <div className="min-h-screen bg-red-400 w-[1px]">
            { images.map((image) => {
                return(
                    <img key={image.id} src={image.img} alt={image.alt} className="w-full max-w-7xl mx-auto p-5" />)
                }) 
            }
        </div>
        </Section>
    )
}