import React, { useRef, useEffect, useLayoutEffect } from 'react';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin)

import logo from '/vector/logo.svg'
import Navigation from './Navigation';

function Logo() {
    return <img src={logo} alt="SystemRuhr" id="logo" className="h-[40px] lg:h-[60ox] mx-left" />;
}

export default function Header() {

    const headerRef = useRef();

    useLayoutEffect(() => {

        ScrollTrigger.create({
            start: '+=200px',
            onEnter: function () {
                headerAnimation('forward')
            },
            onLeaveBack: function () {
                headerAnimation('reverse')
            }
          });

          return () => {
            ScrollTrigger.killAll();
          };

    }, [])

    const headerAnimation = (direction) => {
        gsap.to(headerRef.current, {
            backgroundColor: direction === 'forward' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0)',
            duration: 0.4,
            onComplete: () => {},
            ease: 'power2.inOut',
        });
    };




    return (
    <header ref={headerRef} className="fixed top-0 left-0 w-full py-[10px] h-[60px] border-b border-neutral-700/80 z-[6000]">
        <div className="max-w-7xl w-full mx-auto relative px-5">
            <Logo />
            {/*<Navigation />
            
            <div className="absolute right-0 top-0 bottom-0 bg-white/0 w-[40px] h-40px">
            <label for="nav-toggle-input">
                <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" className="w-[40px]">
                    <rect x="7" y="9" width="26" height="2" fill="#fff"></rect>
                    <rect x="7" y="19" width="26" height="2" fill="#fff"></rect>
                    <rect x="7" y="29" width="26" height="2" fill="#fff"></rect>
                </svg>
            </label>
            <input type="checkbox" id="nav-toggle-input" className="" />
            </div>
            */}
        </div>
    </header> 
    )
}