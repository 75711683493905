import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
import { useGSAP } from "@gsap/react";
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import useStore from "../../Store";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin)

export default function Footer() {

    const footerRef = useRef();
    const soundOn = useStore((state) => state.soundOn);
    const setSoundOn = useStore((state) => state.setSoundOn);

    const handleSoundToggle = () => {
        if (!soundOn) {
            setSoundOn(true);
        }
        else {
            setSoundOn(false);
        }
    }

    useGSAP((context, contextSafe) => {
        const animateIn = contextSafe(() => {
            // ...
        });
    }, [])


    return (
    <footer ref={footerRef} className="fixed bottom-0 left-0 w-full  bg-black/60 z-[5000] h-[50px]">
        <div className="w-full max-w-7xl mx-auto flex flex-row text-xs h-full">
            <div className="hidden sm:flex text-neutral-500 self-center pl-2 ">
                Copyright &copy; 2024 SystemRuhr
            </div>
            <div className="grow pr-2 text-neutral-500 lg:pl-40 lg:pr-40 flex flex-col self-center">

                {/* <div className="bg-gray-600 mx-auto w-[100px] md:w-[150px] lg:w-[200px] h-[8px]">
                    <div className="bg-emerald-400 h-2" style={{ width: '50%' }}></div>
                </div> */}

            </div>
            <div className="shrink text-neutral-500 self-center">
                
                <div id="sound-toggle" onClick={handleSoundToggle} className={`inline-flex items-center gap-4 cursor-pointer ${soundOn ? 'active' : ''}`}>
                    <div className="w-[70px] text uppercase hidden sm:block">{soundOn ? 'Sound on' : 'Sound off'}</div>
                    <div className="wave">
                        <div className="bar"></div><div className="bar"></div><div className="bar"></div><div className="bar"></div><div className="bar"></div>
                        <div className="bar"></div><div className="bar"></div><div className="bar"></div><div className="bar"></div><div className="bar"></div>
                    </div>
                </div>

            </div>
        </div>
    </footer>  
    )
}