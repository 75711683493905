import React, { useRef, useState, useEffect } from 'react'
import * as THREE from 'three'
import { Color, FileLoader } from "three";
import { useGLTF, shaderMaterial, Float, MeshReflectorMaterial } from "@react-three/drei";
import { useFrame, useThree, useLoader, extend } from "@react-three/fiber";
import { randFloat } from "three/src/math/MathUtils";

import vertexShader from "../../shader/portalVertexShader.glsl";
import fragmentShader from "../../shader/portalFragmentShader.glsl";

// Create Shader for Portal
// call with portalShaderMaterial
const PortalShaderMaterial = shaderMaterial({
  uColor: new Color("orange"),
  uTime: 0,
}, vertexShader, fragmentShader);

extend({ PortalShaderMaterial });

export default function Portal(props) {

  const { nodes, materials } = useGLTF('/models/portal.glb')
  const portalMaterialRef = useRef();
  const portalModelRef = useRef();
  const portalGroupsRef = useRef();

  useFrame(({ clock }) => {
    //portalMaterialRef.current.aSpeed += clock.getElapsedTime();

    // const timer = setTimeout(() => {
    //   portalMaterialRef.current.aSpeed = 0;
    //   console.log("test")
    //   clearTimeout(timer);

    // }, 5000)

  });  

  useFrame((state, delta) => {
    portalMaterialRef.current.uTime += delta * 0.4;
  })

  return (
    <Float rotationIntensity={0.9} floatIntensity={1} speed={3} floatingRange={[-0.1,0.1]} >
    <group ref={portalGroupsRef}>
    <mesh position={[0, 1.2, 0]} renderOrder={201} layers={0}>
        <circleGeometry args={[0.5, 32]}>
          <bufferAttribute
            attach={"attributes-aSpeed"}
            count={1 * ((32 + 1) * (32 + 1))}
            array={new Float32Array( 1 * (32 + 1) * (32 + 1) ).map(() => randFloat(1, 3))}
            itemSize={1}
          />
        </circleGeometry>
        <portalShaderMaterial 
          ref={portalMaterialRef} 
          doubleSided={true} 
          blendMode={THREE.AdditiveBlending}
          uColor="lightblue" 
          depthTest={false}
          depthWrite={true}
          />
    </mesh>
    <group ref={portalModelRef} rotation={[Math.PI , Math.PI / 2, 0]} scale={[0.3, 0.3, 0.3]} position={[0, 1.2, 0]} {...props} dispose={null}>  
    <group>
      <mesh 
        //geometry={nodes.ring_outer.geometry}
        //material={nodes.ring_outer.material}
        rotation={[0, 0, -Math.PI / 2]}
        scale={1.536}
      >
        <bufferGeometry attach="geometry" {...nodes.ring_outer.geometry} />
         <MeshReflectorMaterial 
          color="#ffffff"
          blur={[1024, 1024]}
          mixBlur={1}
          mixStrength={1}
          mixContrast={1}
          resolution={1024}
          mirror={1}
          depthScale={0}
          minDepthThreshold={6.5}
          maxDepthThreshold={1}
          depthToBlurRatioBias={0.45}
          roughness={1}
        /> 
        <meshBasicMaterial attach="material" color="#333333" />
      </mesh>
      <mesh
        geometry={nodes.ring_inner.geometry}
        material={nodes.ring_inner.material}
        rotation={[1.374, 0, -Math.PI / 2]}
        scale={1.387}
      />
      <mesh
        geometry={nodes.tooth_1.geometry}
        material={nodes.tooth_1.material}
        position={[0, -0.013, 0]}
        scale={[-0.217, -0.488, -0.488]}
      />
      <mesh
        geometry={nodes.tooth_2.geometry}
        material={nodes.tooth_2.material}
        position={[0, -0.027, 0]}
        rotation={[-Math.PI / 4, 0, 0]}
        scale={[-0.217, -0.488, -0.488]}
      />
      <mesh
        geometry={nodes.tooth_3.geometry}
        material={nodes.tooth_3.material}
        position={[0, -0.027, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={[-0.217, -0.488, -0.488]}
      />
      <mesh
        geometry={nodes.tooth_4.geometry}
        material={nodes.tooth_4.material}
        position={[0, -0.027, 0]}
        rotation={[-2.356, 0, 0]}
        scale={[-0.217, -0.488, -0.488]}
      />
      <mesh
        geometry={nodes.tooth_5.geometry}
        material={nodes.tooth_5.material}
        position={[0, -0.027, 0]}
        rotation={[Math.PI, 0, 0]}
        scale={[-0.217, -0.488, -0.488]}
      />
      <mesh
        geometry={nodes.tooth_6.geometry}
        material={nodes.tooth_6.material}
        position={[0, -0.027, 0]}
        rotation={[2.356, 0, 0]}
        scale={[-0.217, -0.488, -0.488]}
      />
      <mesh
        geometry={nodes.tooth_7.geometry}
        material={nodes.tooth_7.material}
        position={[0, -0.027, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[-0.217, -0.488, -0.488]}
      />
      <mesh 
        geometry={nodes.tooth_8.geometry}
        material={nodes.tooth_8.material}
        position={[0, -0.027, 0]}
        rotation={[Math.PI / 4, 0, 0]}
        scale={[-0.217, -0.488, -0.488]}
      />
    </group>
    </group>
    </group>
    </Float>
  )
}

useGLTF.preload('/models/portal.glb')