import React, { useEffect, useRef } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

export const MotionFadeInBottom = ({ children, delay = 0 }) => {
    
    const ref = useRef(null);
    const isInView = useInView(ref, { 
        once: false, 
    });
    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible");
        } else {
            mainControls.start("hidden");
        }
    }, [isInView]);

    return (
        <div ref={ref} style={{ position: "relative", overflow: "hidden" }}>
        <motion.div 
            variants={{
                hidden: { opacity: 0, y: 50 },
                visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ 
                duration: 1, 
                delay: delay
            }}
        >
            {children}
        </motion.div>
        </div>
    );
};