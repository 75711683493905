import React, { useRef, useEffect } from 'react'
import { useThree } from '@react-three/fiber'
import { useGLTF, useAnimations, Html } from '@react-three/drei'
import useStore from "../../Store";

export default function Fan(props) {

  const camera = useThree(state => state.camera)
  const loading = useStore((state) => state.loading);

  const groupRef = useRef()
  const { nodes, materials, animations } = useGLTF('/models/fan.glb', true)
  const { actions } = useAnimations(animations, groupRef)

  const fanRotation = actions["fanRotation"]

  useEffect(() => {
    if (fanRotation) fanRotation.play();
  }, [loading]);

  const handleClick = () => {
    if (fanRotation) fanRotation.play();
    console.log("fanRotation")
  }

  return (
    <group ref={groupRef} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="corpse"
          castShadow
          receiveShadow
          geometry={nodes.corpse.geometry}
          material={materials.PLST}
          scale={[0.677, 0.677, 0.122]}
        />
        <Html position={[-0.8, 0.1, 0]} center wrapperClass="">
          <div className="bg-black/60 px-5 py-3 border border-emerald-400 uppercase font-oxanium cursor-pointer" onClick={handleClick}>Cooling</div>
        </Html>
        <group
          name="suspension"
          position={[0, 0, 0.112]}
          rotation={[Math.PI / 2, 0, 0]}
          scale={[0.228, 0.008, 0.228]}>
          <mesh
            name="suspension-geometry"
            castShadow
            receiveShadow
            geometry={nodes['suspension-geometry'].geometry}
            material={materials.PLST}
          />
          <mesh
            name="suspension-geometry_1"
            castShadow
            receiveShadow
            geometry={nodes['suspension-geometry_1'].geometry}
            material={materials.Material}
          />
        </group>
        <group
          name="cable"
          position={[0.084, 0.345, 0.109]}
          rotation={[0, 0, -Math.PI / 4]}
          scale={[0.006, 0.023, 0.004]}>
          <mesh
            name="cable-geometry"
            castShadow
            receiveShadow
            geometry={nodes['cable-geometry'].geometry}
            material={materials.RJG}
          />
          <mesh
            name="cable-geometry_1"
            castShadow
            receiveShadow
            geometry={nodes['cable-geometry_1'].geometry}
            material={materials.YLM}
          />
          <mesh
            name="cable-geometry_2"
            castShadow
            receiveShadow
            geometry={nodes['cable-geometry_2'].geometry}
            material={materials.NRJ}
          />
        </group>
        <mesh
          name="fan"
          castShadow
          receiveShadow
          geometry={nodes.fan.geometry}
          material={materials.PLST}
          position={[0, 0, -0.069]}
          scale={[0.115, 0.082, 0.007]}
        />
      </group>
    </group>
  )

}

useGLTF.preload('/models/fan.glb')
