import { useEffect } from "react";
import * as THREE from 'three'
import { useThree } from "@react-three/fiber"
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { useGSAP } from "@gsap/react";
import { useControls, button, buttonGroup, folder } from "leva";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin)

export default function ResponsiveCamera(props) {

  const { camera, size } = useThree();

  const { cameraPosition, cameraRotation } = useControls({
    cameraPosition: folder(
      {
        posX: -2, 
        posY: 1, 
        posZ: 5,
        'Set Position': button((get) => {
          camera.position.set( get('cameraPosition.posX'), get('cameraPosition.posY'), get('cameraPosition.posZ') )
        })
      },
      { collapsed: false }
    ),
    cameraRotation: folder(
      {
        rotX: 0, 
        rotY: 0, 
        rotZ: 0,
        'Set Rotation': button((get) => {
          // Deg to Rad
          const finalRotX = THREE.MathUtils.degToRad( get('cameraRotation.rotX') );
          const finalRotY = THREE.MathUtils.degToRad( get('cameraRotation.rotY') );
          const finalRotZ = THREE.MathUtils.degToRad( get('cameraRotation.rotZ') );
          camera.rotation.set( finalRotX, finalRotY, finalRotZ )
        })
      },
      { collapsed: false }
    ),

  });

  // Initial position: -2, 1, 5
  const cameraStops = [
  {
      id: "web",
      title: "Web View",
      trigger: "#camera-fly-web",
      start: "top bottom",
      triggerEnd: "",
      end: "bottom bottom",
      ease: "power1.inOut",
      cameraPosition: { x: 26, y: 0.5, z: 30 },
      cameraRotation: { x: 0, y: -90, z: 0 }
  },
  {
      id: "print",
      title: "Print View",
      trigger: "#camera-fly-print",
      start: "top bottom",
      triggerEnd: "",
      end: "bottom bottom",
      ease: "power1.inOut",
      cameraPosition: { x: 28, y: 0.5, z: 57 },
      cameraRotation: { x: 10, y: -135, z: 0 }
  },
  {
      id: "gallery",
      title: "Gallery View",
      trigger: "#camera-fly-gallery",
      start: "top bottom",
      triggerEnd: "",
      end: "bottom bottom",
      ease: "power1.inOut",
      cameraPosition: { x: 0, y: 39, z: 80 },
      cameraRotation: { x: 0, y: -180, z: 0 },
  },
  {
      id: "hardware",
      title: "Hardware View",
      trigger: "#camera-fly-hardware",
      start: "top bottom",
      triggerEnd: "",
      end: "bottom bottom",
      ease: "power1.inOut",
      cameraPosition: { x: -28, y: 0.5, z: 56 },
      cameraRotation: { x: 0, y: -210, z: 0 }
  },  
  {
      id: "about",
      title: "About View",
      trigger: "#camera-fly-about",
      start: "top bottom",
      triggerEnd: "",
      end: "bottom bottom",
      ease: "power1.inOut",
      cameraPosition: { x: -26, y: 1, z: 30 },
      cameraRotation: { x: -25, y: -270, z: 0 },
  },
  ]


  useGSAP(() => {

    cameraStops.forEach(({ id, trigger, start, triggerEnd, end, ease, cameraPosition, cameraRotation }) => {

      const animationTimeline = gsap.timeline({
        scrollTrigger: {
            immediateRenders: false,
            ease: ease,
            trigger: trigger,
            start: start, 
            endTrigger: triggerEnd,
            end: end, 
            scrub: 1,
            onUpdate: () => {
              camera.updateProjectionMatrix();
            },
        },
      });
  
      animationTimeline
      .to(camera.position, { 
        x: cameraPosition.x, 
        y: cameraPosition.y, 
        z: cameraPosition.z,      
      }).to(camera.rotation, {
        x: THREE.MathUtils.degToRad(cameraRotation.x),
        y: THREE.MathUtils.degToRad(cameraRotation.y),
        z: THREE.MathUtils.degToRad(cameraRotation.z),
      }, "<");

    });

    return () => {
      gsap.killTweensOf(camera.position);
      gsap.killTweensOf(camera.rotation);
    };

  }, []);  


  function setCameraFov() {
    if (size.width < 768) {
      camera.fov = 90
    } else if (size.width > 768 && size.width < 1260) {
      camera.fov = 70
    } else {
      camera.fov = 50
    }
  }


  useEffect(() => {
    setCameraFov()
  }, [size]);


  useEffect(() => {
    setCameraFov()
    camera.position.set(-2, 1, 5)
    camera.near = 0.1
    camera.far = 250
    camera.rotation.set( -0.09966865249116204, -0.3787938928253967, -0.036963169913538804 );
    camera.updateProjectionMatrix()

    camera.layers.enable( 0 );
    camera.layers.enable( 1 );
    camera.layers.enable( 2 );
    camera.layers.enable( 3 );
    camera.layers.enable( 4 );
    camera.layers.enable( 5 );

    //camera.lookAt(0, 0.5, 0)
    // const rotX = THREE.MathUtils.radToDeg( camera.rotation.x );
    // const rotY = THREE.MathUtils.radToDeg( camera.rotation.y );
    // const rotZ = THREE.MathUtils.radToDeg( camera.rotation.z );
    // console.log(rotX)
    // console.log(rotY)
    // console.log(rotZ)
    // console.log(camera.rotation)

    // const rotXrad = THREE.MathUtils.degToRad( -5.710593137499643 );
    // const rotYrad = THREE.MathUtils.degToRad( -21.703291364226065 );
    // const rotZrad = THREE.MathUtils.degToRad( -2.1178336334707177 );
    //camera.rotation.set(rotXrad, rotYrad, 0);

  }, [camera]);

}
