varying vec2 vUv;
uniform sampler2D uTexture;

void main() {
    vec2 uv = vUv;
    vec4 curTexture = texture2D(uTexture, uv);

    gl_FragColor = curTexture;
    
    #include <tonemapping_fragment>
    #include <colorspace_fragment>
}